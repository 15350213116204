<template>
  <div id="newsStoryContent">
    <p id="Text1">
      How would you like to have one of your creations become part of the LEGO Universe world? You could have thousands of players using something you have created! Now you have that opportunity. Build the best LEGO Universe Rocket and submit it to the Creation Lab this month. Three winners will receive a full set of LEGO Ninjago sets as well as a LEGO Space Shuttle Adventure Set. The Grand Prize winner may even have their creation added to the game as an official LEGO Universe rocket!
    </p>
    <p class="center">
      <!-- picF3F5D0F905154D4FDBC67B581F8EF39D.jpg -->
      <img src="@/assets/news-network/build-competition-rockets.jpg" class="rounded">
    </p>
    <p>
      You can build your rocket on your LEGO Universe property or out of your own LEGO bricks, (you do not have to be a LEGO Universe subscriber to enter into this contest). We will be judging entries on creativity and imagination – do not try and make someone else's rocket or mimic something you have seen on TV or in the movies. We want you to be original!
    </p>
    <p>
      Between now and February 11, 2011, take a
      <router-link to="/support/question/476/doc/514">screenshot</router-link>
      of your rocket (if built in game) or a picture (if you use your own bricks). Make sure that you are only taking a picture of your rocket, do not include pictures of people or anything that might distract from your masterpiece. You can start designing your rocket now and the Creation Lab will be set up to accept submissions beginning January 25th!
      Submit it to the Creation Lab under the "January Build Competition" challenge.
    </p>
    <p>
      Once your image has been approved, it will show up on the Creation Lab webpage . On February 12, 2011 we will judge the entries and select three winners. Make sure to follow these guidelines to have the best shot at winning:
    </p>
    <p>
      1. Rockets should be made of three modules – nosecone, cockpit and engine.<br>
      2. The Nosecone section should be no more than 10 studs long.<br>
      3. Cockpits should be no more than four (4) studs wide and six (6) studs long.<br>
      4. Engines should be no more than six (6) studs long.
    </p>
    <p>
      If you need some inspiration, check out our interview with digital model builder
      <router-link :to="{ name: 'story', params: {id: 261632} }">Duane Hess</router-link>
      and the instructions on how to build these three rockets:<br>
      1. <router-link :to="{ name: 'story', params: {id: 258794} }">Nimbus Rocket</router-link><br>
      2. <router-link :to="{ name: 'story', params: {id: 262431} }">Red Ninja Rocket</router-link><br>
      3. <router-link :to="{ name: 'story', params: {id: 266680} }">Classic Rocket</router-link>
    </p>
    <p>
      The top three winners will receive a LEGO Space Shuttle Adventure set and one of each Ninjago set:
    </p>
    <p>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=10213&amp;LangId=2057&amp;ShipTo=US">1 LEGO Space Shuttle Adventure Set (#10213)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2505&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Garmadon's Dark Fortress (#2505)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2504&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Spinjitzu Dojo (#2504)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2518&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Nuckal's ATV (#2518)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2263&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Turbo Shredder (#2263)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2111&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Kai (#2111)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2112&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Cole (#2112)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2113&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Zane (#2113)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2114&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Chopov (#2114)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2115&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Bonezai (#2115)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2116&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Krazi(#2116)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2257&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Spinjitzu Starter Set (#2257)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2258&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Ninja Ambush (#2258)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2259&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Skull Motorbike (#2259)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2260&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Ice Dragon Attack (#2260)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2516&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Ninja Training Outpost (#2516)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2519&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Skelton Bowling (#2519)</a><br>
      • <a href="https://web.archive.org/web/20110120113329/http://shop.lego.com/product/?p=2520&amp;LangId=2057&amp;ShipTo=US">1 LEGO Ninjago Battle Arena (#2520)</a>
    </p>
    <p>That is over $400 in LEGO sets!</p>
    <p>
      Once the winners have been determined, the LEGO Universe design team will determine which of the three rockets will be added to LEGO Universe game in the next few months. Rockets have to meet certain criteria to function in the game, and it is possible that the Grand Prize winner's rocket will not be added to the game. We may also have to modify the rocket to make it functional.
    </p>
    <p>Put on your thinking hat and build the best rocket you can!</p>
    <p>
      Discuss this contest on the
      <router-link to="/messageboards/3342776">forums</router-link>!
    </p>
  </div>
</template>
